@import '../../global/styles/lib';

.error404 {
    .entry-content {
        margin-bottom: 2rem;
        a {
            text-decoration: underline;
        } // a

    } // .entry-content

} // .error404